import React, { useEffect } from 'react';
import '../styles/Login.css';
import Userfront, { LoginForm } from '@userfront/toolkit';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const { user } = Userfront;

  useEffect(() => {
    if (user && user.userId) {
      navigate('/home');
    }
  }, [user, navigate]);

  return (
    <div className="container">
      <h1 className="logo">Parb</h1>
      <LoginForm />
      <footer className="footer">
        © 2024 Parb AI. All rights reserved. <a href="/terms">Terms & Conditions</a> |{' '}
        <a href="/privacy">Privacy Policy</a>
      </footer>
    </div>
  );
};

export default Login;
