import React from 'react';
import '../styles/Home.css';

const Home = () => {
  return (
    <div className="container">
      <h1 className="welcome-message">Welcome to Parb AI Customer Service Dashboard</h1>
    </div>
  );
};

export default Home;
