import { del, getFile, post } from './methods.ts';

export const confirmCall = async (callId: number) => {
  return await post(`calls/${callId}`, {});
};

export const deleteCall = async (callId: string) => {
  return await del(`calls/${callId}`);
};

export const getCallRecording = async (callId: number) => {
  return await getFile(`clinic-management/get-recording`, { callId });
};
