import React, { useEffect, useState } from 'react';
import { createContext } from 'react';
import { ParbProduct, getUserAccessibleProducts } from '../network/users.ts';
import { toast } from 'react-toastify';
import Userfront from '@userfront/toolkit';

type UserContextType = {
  isLoading: boolean;
  userAccessibleProducts: ParbProduct[];
};

const defaultUserContext: UserContextType = {
  isLoading: true,
  userAccessibleProducts: [],
};

export const UserContext = createContext<UserContextType>(defaultUserContext);

export const UserContextProvider = ({ children }) => {
  const [userAccessibleProducts, setUserAccessibleProducts] = useState<ParbProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    tokens: { accessToken },
  } = Userfront;

  const fetchUserAccessibleProducts = async () => {
    try {
      const accessibleProducts = await getUserAccessibleProducts();
      setUserAccessibleProducts(accessibleProducts);
    } catch (e) {
      console.log(e);
      toast.error(e.response?.data?.message);
      setUserAccessibleProducts([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!accessToken) return;
    fetchUserAccessibleProducts();
  }, [accessToken]);

  return (
    <UserContext.Provider value={{ isLoading, userAccessibleProducts }}>
      {children}
    </UserContext.Provider>
  );
};
