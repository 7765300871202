import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import Modal from 'react-modal';
import Select from 'react-select'; // Import react-select
import '../styles/Appointments.css'; // Import the CSS file
import { format } from 'date-fns';
import Userfront from '@userfront/toolkit';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  ReceiveSquareIcon,
  ClockwiseArrowsIcon,
  ExportIcon,
  PhoneIcon,
  SortIcon,
  UploadIcon,
  TrashIcon,
} from '../icons';
import { CallStatus, SseTopic, subscribeToSse } from '../network/sse.ts';
import {
  ApptOrderBy,
  exportAppointmentsCSV,
  getAppointments,
  uploadAppointmentsCSV,
} from '../network/appointments.ts';
import { getClinics } from '../network/clinics.ts';
import { confirmCall, deleteCall, getCallRecording } from '../network/calls.ts';
import { OrderDirection } from '../network/types.ts';

// Initialize modal styling to avoid accessibility issues
Modal.setAppElement('#root');

const Appointments = () => {
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false); // New state for filter modal
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');
  const [clinics, setClinics] = useState([]);
  const [selectedClinicId, setSelectedClinicId] = useState('');
  const [filterClinicIds, setFilterClinicIds] = useState(''); // State for filter dropdown selection
  const [filterfromDate, setFilterfromDate] = useState(''); // State for filter date from
  const [filtertoDate, setFiltertoDate] = useState(''); // State for filter date to
  const [selectedAppointments, setSelectedAppointments] = useState(new Set()); // State to track selected checkboxes
  const fileInputRef = useRef(null);
  const { user } = Userfront;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [orderBy, setOrderBy] = useState(ApptOrderBy.CREATED_AT);
  const [orderDirection, setOrderDirection] = useState(OrderDirection.DESC);

  const fetchAppointmentsData = useCallback(
    async (clinicIds = '', fromDate = '', toDate = '') => {
      try {
        const response = await getAppointments(
          clinicIds,
          fromDate,
          toDate,
          orderBy,
          orderDirection,
          entriesPerPage,
          (currentPage - 1) * entriesPerPage,
        );
        setAppointmentsData(response.data.items);
        setTotalAppointments(response.data.totalCount);
      } catch (err) {
        toast.error(`Error fetching appointments: ${err.response?.data?.message}`);
        setAppointmentsData([]);
        setTotalAppointments(0);
      } finally {
        setLoading(false);
      }
    },
    [orderBy, orderDirection, entriesPerPage, currentPage],
  );

  const fetchClinics = useCallback(async () => {
    try {
      const response = await getClinics(user.email);
      setClinics(response.data);
    } catch (err) {
      toast.error(`Error fetching clinics: ${err.response?.data?.message}`);
      setClinics([]);
    }
  }, [user.email]);

  const dataRef = useRef();
  useEffect(() => {
    dataRef.current = appointmentsData;
  }, [appointmentsData]);

  useEffect(() => {
    const handleCallStatusChange = ({ data }) => {
      const { callId, callStatus } = JSON.parse(data);

      const updatedAppointmentsData = dataRef.current.map((appointment) => {
        if (appointment.id === callId) {
          return { ...appointment, status: callStatus };
        }
        return appointment;
      });
      setAppointmentsData(updatedAppointmentsData);
    };
    const handleCallOutcomeChange = ({ data }) => {
      const { callId, callOutcome } = JSON.parse(data);

      const updatedAppointmentsData = dataRef.current.map((appointment) => {
        if (appointment.id === callId) {
          return { ...appointment, outcome: callOutcome };
        }
        return appointment;
      });
      setAppointmentsData(updatedAppointmentsData);
    };

    const es = subscribeToSse();
    es.addEventListener(SseTopic.CALL_STATUS, handleCallStatusChange);
    es.addEventListener(SseTopic.CALL_OUTCOME, handleCallOutcomeChange);

    return () => es.close();
  }, []);

  useEffect(() => {
    fetchAppointmentsData();
    fetchClinics(); // Fetch clinics when page loads
  }, [fetchAppointmentsData, fetchClinics]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleEntriesPerPageChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    const flipDirection = (direction) => {
      if (direction === OrderDirection.ASC) return OrderDirection.DESC;
      return OrderDirection.ASC;
    };

    if (orderBy === key) setOrderDirection(flipDirection(orderDirection));
    else setOrderDirection(OrderDirection.ASC);

    setOrderBy(key);
  };

  const totalPages = Math.ceil(totalAppointments / entriesPerPage);

  const handleSelectAllChange = () => {
    const updatedSelectAllChecked = !selectAllChecked;
    setSelectAllChecked(updatedSelectAllChecked);

    if (updatedSelectAllChecked) {
      // Add all current entries to selectedAppointments
      const newSelectedAppointments = new Set(selectedAppointments);
      appointmentsData.forEach(({ id }) => newSelectedAppointments.add(id));
      setSelectedAppointments(newSelectedAppointments);
    } else {
      // Remove all current entries from selectedAppointments
      const newSelectedAppointments = new Set(selectedAppointments);
      appointmentsData.forEach(({ id }) => newSelectedAppointments.delete(id));
      setSelectedAppointments(newSelectedAppointments);
    }
  };

  const handleCheckboxChange = (appointmentId) => {
    const newSelectedAppointments = new Set(selectedAppointments);
    if (newSelectedAppointments.has(appointmentId)) {
      newSelectedAppointments.delete(appointmentId);
    } else {
      newSelectedAppointments.add(appointmentId);
    }
    setSelectedAppointments(newSelectedAppointments);
  };

  const handleUploadNewAppointments = async (event) => {
    event.preventDefault();
    const file = fileInputRef.current.files[0];
    if (!file) {
      setUploadError('Please upload a csv file.');
      return;
    }

    if (!selectedClinicId) {
      setUploadError('Please select a clinic.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('clinicId', selectedClinicId);

    try {
      await toast.promise(uploadAppointmentsCSV(formData), {
        error: 'Error uploading Appointments',
        pending: 'Uploading Appointments',
        success: 'Appointments uploaded successfully',
      });

      await fetchAppointmentsData(); // Fetch the updated appointments data
      setIsModalOpen(false); // Close the modal
      fileInputRef.current.value = ''; // Clear the file input
      setSelectedFileName(''); // Reset the file name state
    } catch (error) {
      toast.error(`${error.response?.data?.message}`);
    }
  };

  const handleDragDropAreaClick = () => {
    fileInputRef.current.click();
  };

  const getButtonColor = (action) => {
    switch (action) {
      case 'Call in Progress':
        return 'muted-yellow'; // CSS class
      case 'CONFIRMED':
        return 'muted-green'; // CSS class
      case 'FAILED':
        return 'muted-red'; // CSS class
      case 'VM_FAILED':
        return 'muted-red'; // CSS class
      case 'TRANSFERRED':
      case 'VM_SUCCESSFUL':
        return 'muted-orange'; // CSS class
      default:
        return 'white'; // Default CSS class
    } //
  };

  const handleConfirmAppointment = async (appointment, index) => {
    try {
      await confirmCall(appointment.id);
    } catch (error) {
      toast.error(
        `Error confirming ${appointment.patientName}'s appointment: ${error.response?.data?.message}`,
      );
    }
  };

  const formatTimeTo12Hour = (time) => {
    if (!time) return 'Invalid';
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(hours, minutes);
    return format(date, 'h:mm a');
  };

  const handleConfirmAllAppointments = async () => {
    const confirmableAppointments = appointmentsData.filter(({ outcome }) => !outcome);
    for (let i = 0; i < confirmableAppointments.length; i++) {
      setTimeout(async () => {
        await handleConfirmAppointment(
          confirmableAppointments[i],
          appointmentsData.indexOf(confirmableAppointments[i]),
        );
      }, i * 1000); // 1 second apart
    }
  };

  const handleConfirmSelectedAppointments = async () => {
    const selectedAppointmentData = appointmentsData.filter(
      ({ id, outcome }) => selectedAppointments.has(id) && !outcome,
    );
    for (let i = 0; i < selectedAppointmentData.length; i++) {
      setTimeout(async () => {
        await handleConfirmAppointment(
          selectedAppointmentData[i],
          appointmentsData.indexOf(selectedAppointmentData[i]),
        );
      }, i * 1000); // 1 second apart
    }
  };

  const handleCheckAppointmentStatus = async () => {
    await fetchAppointmentsData(filterClinicIds, filterfromDate, filtertoDate);
    toast.success('Appointment status updated!');
  };

  const handleExportCSV = async () => {
    toast.promise(
      (async () => {
        const response = await exportAppointmentsCSV(filterClinicIds, filterfromDate, filtertoDate);
        downloadFile(response.data, 'appointments.csv');
      })(),
      {
        pending: 'Exporting appointments CSV...',
        success: 'Appointments CSV exported successfully!',
        error: {
          render({ data }) {
            // data is the error object
            return `Error exporting CSV: ${data.response?.data?.message || data.message}`;
          },
        },
      },
    );
  };

  const handleDownloadCallRecording = async (callId) => {
    toast.promise(
      getCallRecording(callId).then((r) => downloadFile(r.data, `call-recording-${callId}.mp3`)),
      {
        error: 'Error downloading call recording',
        pending: 'Downloading call recording',
        success: 'Call recording downloaded',
      },
    );
  };

  const downloadFile = (data, fileName) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
  };

  const openModal = () => {
    fetchClinics(); // Fetch clinics when modal is opened
    setIsModalOpen(true);
  };

  const openFilterModal = () => {
    fetchClinics(); // Fetch clinics when filter modal is opened
    setIsFilterModalOpen(true);
  };

  const handleClinicChange = (selectedOptions) => {
    setFilterClinicIds(selectedOptions.map((option) => option.value).join(','));
  };

  const handleApplyFilter = () => {
    fetchAppointmentsData(filterClinicIds, filterfromDate, filtertoDate);
    setIsFilterModalOpen(false); // Close filter modal after applying
    toast.success('Filters applied!');
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Remove all non-digit characters
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber; // Return original if format is not matched
  };

  const formatAppointmentDate = (dateString) => {
    const [year, month, day] = dateString.split('T')[0].split('-');
    const date = new Date(year, month - 1, day);
    return format(date, 'MMMM do, yyyy');
  };

  const generatePageNumbers = (currentPage, totalPages) => {
    const delta = 2; // Number of pages to show on either side of the current page
    const range = [];
    const rangeWithDots = [];
    let l;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= currentPage - delta && i <= currentPage + delta)) {
        range.push(i);
      }
    }

    range.forEach((i) => {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    });

    return rangeWithDots;
  };

  const closeModal = () => {
    setIsModalOpen(false);
    fileInputRef.current.value = ''; // Clear the file input
    setSelectedFileName(''); // Reset the file name state
  };

  // Use this function to close the modal instead of setting the state directly

  const openDeleteModal = (appointmentId) => {
    setSelectedAppointmentId(appointmentId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteAppointment = async () => {
    if (!selectedAppointmentId) return;

    try {
      await deleteCall(selectedAppointmentId);
      setAppointmentsData((prevData) => prevData.filter(({ id }) => id !== selectedAppointmentId));
      toast.success('Appointment deleted successfully');
    } catch (error) {
      toast.error(`Error deleting appointment: ${error.response?.data?.message}`);
    } finally {
      setIsDeleteModalOpen(false);
      setSelectedAppointmentId(null);
    }
  };

  const getOrderArrow = (column) => {
    return orderBy === column ? (orderDirection === OrderDirection.ASC ? '↑' : '↓') : '';
  };

  return (
    <div className="container">
      <h2 className="title">Appointment Confirmation</h2>
      <div className="button-container">
        <button className="upload-button button-common" onClick={openModal}>
          <UploadIcon /> Upload New Appointments
        </button>
        <button className="confirm-all-button button-common" onClick={handleConfirmAllAppointments}>
          <PhoneIcon /> Confirm All
        </button>
        <button
          className="confirm-selected-button button-common"
          onClick={handleConfirmSelectedAppointments}
        >
          <PhoneIcon /> Confirm Selected Appointments
        </button>
        <button
          className="check-status-button button-common"
          onClick={handleCheckAppointmentStatus}
        >
          <ClockwiseArrowsIcon /> Check Appointment Status
        </button>
        <button className="filter-button button-common" onClick={openFilterModal}>
          <SortIcon /> Filter
        </button>
        <button className="export-button button-common" onClick={handleExportCSV}>
          <ExportIcon /> Export csv
        </button>
      </div>
      {loading ? (
        <p className="message">Loading...</p>
      ) : appointmentsData.length === 0 ? (
        <p className="message">You don't have upcoming appointments</p>
      ) : (
        <>
          <div className="table-wrapper">
            <table className="table">
              <thead className="table-head">
                <tr className="table-row">
                  <th className="table-header">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={appointmentsData.every(({ id }) => selectedAppointments.has(id))}
                      onChange={handleSelectAllChange}
                    />
                  </th>
                  <th
                    className="table-header name-column"
                    onClick={() => handleSort(ApptOrderBy.PATIENT_NAME)}
                  >
                    Name {getOrderArrow(ApptOrderBy.PATIENT_NAME)}
                  </th>
                  <th
                    className="table-header phone-column"
                    onClick={() => handleSort(ApptOrderBy.PATIENT_PHONE)}
                  >
                    Phone {getOrderArrow(ApptOrderBy.PATIENT_PHONE)}
                  </th>
                  <th
                    className="table-header appointment-date-column"
                    onClick={() => handleSort(ApptOrderBy.APPOINTMENT_DATE)}
                  >
                    Appointment Date {getOrderArrow(ApptOrderBy.APPOINTMENT_DATE)}
                  </th>
                  <th
                    className="table-header appointment-time-column"
                    onClick={() => handleSort(ApptOrderBy.APPOINTMENT_TIME)}
                  >
                    Time {getOrderArrow(ApptOrderBy.APPOINTMENT_TIME)}
                  </th>
                  <th
                    className="table-header clinic-column"
                    onClick={() => handleSort(ApptOrderBy.CLINIC_NAME)}
                  >
                    Clinic {getOrderArrow(ApptOrderBy.CLINIC_NAME)}
                  </th>
                  <th className="table-header action-column">Action</th>
                </tr>
              </thead>
              <tbody>
                {appointmentsData.map((appointment, index) => (
                  <tr className="table-row" key={appointment.id}>
                    <td className="table-cell">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={selectedAppointments.has(appointment.id)}
                        onChange={() => handleCheckboxChange(appointment.id)}
                      />
                    </td>
                    <td className="table-cell name">{appointment.patientName}</td>
                    <td className="table-cell">
                      <div className="phone-number">
                        <PhoneIcon style={{ marginRight: '8px' }} />
                        {formatPhoneNumber(appointment.patientPhoneNumber)}
                      </div>
                    </td>
                    <td className="table-cell date">
                      {formatAppointmentDate(appointment.appointmentDate)}
                    </td>
                    <td className="table-cell time">
                      {formatTimeTo12Hour(appointment.appointmentTime)}
                    </td>
                    <td className="table-cell clinic">{appointment.clinicName}</td>
                    <td className="table-cell">
                      <button
                        className={`${appointment.outcome ? 'action-outcome' : 'action-button'} 
                          ${
                            new Date(appointment.appointmentDate) < new Date() &&
                            !appointment.outcome
                              ? 'date-passed-button action-outcome'
                              : getButtonColor(appointment.outcome) || 'default-white'
                          }`}
                        onClick={() => {
                          if (
                            !appointment.outcome &&
                            new Date(appointment.appointmentDate) >= new Date()
                          ) {
                            handleConfirmAppointment(appointment, index);
                          }
                        }}
                        disabled={
                          new Date(appointment.appointmentDate) < new Date() && !appointment.outcome
                        }
                      >
                        {new Date(appointment.appointmentDate) < new Date() && !appointment.outcome
                          ? 'Date Passed'
                          : appointment.status !== 'PENDING' &&
                            appointment.status !== 'COMPLETED' &&
                            !appointment.outcome
                          ? 'In Progress'
                          : appointment.outcome
                          ? appointment.outcome === 'CONFIRMED'
                            ? 'Confirmed'
                            : appointment.outcome === 'VM_SUCCESSFUL'
                            ? 'Left Voicemail'
                            : appointment.outcome === 'TRANSFERRED'
                            ? 'Transferred'
                            : appointment.outcome === 'VM_FAILED'
                            ? 'Voicemail Failed'
                            : appointment.outcome === 'FAILED'
                            ? 'Needs Callback'
                            : appointment.outcome
                          : 'Confirm Appointment'}
                      </button>
                    </td>
                    <td className="table-cell">
                      <button
                        className="recording-button"
                        disabled={
                          !['CONFIRMED', 'VM_SUCCESSFUL', 'TRANSFERRED'].includes(
                            appointment.outcome,
                          )
                        }
                        onClick={() => handleDownloadCallRecording(appointment.id)}
                      >
                        <ReceiveSquareIcon />
                        Recording
                      </button>
                    </td>
                    <td className="table-cell">
                      <button
                        className="trash-button"
                        disabled={appointment.status !== CallStatus.PENDING}
                        onClick={() => openDeleteModal(appointment.id)}
                      >
                        <TrashIcon className="trash-icon" />
                      </button>
                    </td>
                    <td className="table-cell">
                      <div className="kebab-menu">
                        <FaEllipsisV />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-controls">
            <div className="page-indicator">
              <span className="page-text">Show</span>
              <select
                className="entries-selector"
                value={entriesPerPage}
                onChange={handleEntriesPerPageChange}
              >
                {Array.from({ length: Math.min(Math.ceil(totalAppointments / 10), 5) }).map(
                  (_, index) => (
                    <option key={index} value={(index + 1) * 10}>
                      {(index + 1) * 10}
                    </option>
                  ),
                )}
              </select>
              <span className="page-text">Rows</span>
            </div>
            <div className="pagination-container">
              {generatePageNumbers(currentPage, totalPages).map((page, index) => (
                <button
                  key={index}
                  className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                  onClick={() => typeof page === 'number' && handlePageChange(page)}
                  disabled={typeof page !== 'number'}
                >
                  {page}
                </button>
              ))}
            </div>
          </div>
        </>
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Upload New Patient Appointments"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="modal-container">
          <button className="close-button" onClick={closeModal}>
            &times;
          </button>
          <div className="modal-content">
            {' '}
            {/* Add a wrapper for padding */}
            <h3 className="modal-title">Upload New Patient Appointments</h3>
            <form className="form" onSubmit={handleUploadNewAppointments}>
              <label className="label" htmlFor="clinic">
                Clinic
              </label>
              <select
                className="select"
                id="clinic"
                name="clinic"
                required
                value={selectedClinicId}
                onChange={(e) => setSelectedClinicId(e.target.value)}
              >
                <option value="">Select Clinic</option>
                {clinics.map((clinic) => (
                  <option key={clinic.clinicId} value={clinic.clinicId}>
                    {clinic.clinicName}
                  </option>
                ))}
              </select>
              <label className="label" htmlFor="file">
                Attachment
              </label>
              <div className="drag-drop-area" onClick={handleDragDropAreaClick}>
                Click to Browse
                <input
                  type="file"
                  className="hidden-input file-input"
                  id="file"
                  name="file"
                  accept=".csv"
                  ref={fileInputRef}
                  onChange={(event) => {
                    const file = event.target.files[0];
                    if (file && file.type === 'text/csv') {
                      setUploadError('');
                      setSelectedFileName(file.name);
                    } else {
                      setUploadError('Please upload a csv file.');
                      setSelectedFileName('');
                    }
                  }}
                />
              </div>
              {selectedFileName && (
                <p className="selected-file">Selected file: {selectedFileName}</p>
              )}
              {uploadError && <p className="error-message">{uploadError}</p>}
              <div className="button-group">
                <button className="cancel-button" type="button" onClick={closeModal}>
                  Cancel
                </button>
                <button className="submit-button" type="submit">
                  Upload
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/* Filter Modal */}
      <Modal
        isOpen={isFilterModalOpen}
        onRequestClose={() => setIsFilterModalOpen(false)}
        contentLabel="Filter Patient Appointments"
        className="custom-filter-modal" // Class for custom filter modal styling
        overlayClassName="custom-modal-overlay" // Reusing overlay class
      >
        <div className="filter-modal-container">
          <button className="close-button" onClick={() => setIsFilterModalOpen(false)}>
            &times;
          </button>
          <h3 className="filter-modal-title">Filter Patient Appointments</h3>
          <div className="dropdown-group">
            <label className="dropdown-label" htmlFor="filter-clinic">
              Clinic
            </label>
            <Select
              id="filter-clinic"
              isMulti
              options={clinics.map((clinic) => ({
                value: clinic.clinicId,
                label: clinic.clinicName,
              }))}
              value={clinics
                .filter((clinic) => filterClinicIds.split(',').includes(clinic.clinicId.toString()))
                .map((clinic) => ({ value: clinic.clinicId, label: clinic.clinicName }))}
              onChange={handleClinicChange}
              className="filter-dropdown"
            />
          </div>
          <div className="dropdown-group">
            <label className="dropdown-label" htmlFor="date-from">
              Date From
            </label>
            <input
              type="date"
              className="filter-dropdown"
              id="date-from"
              value={filterfromDate}
              onChange={(e) => setFilterfromDate(e.target.value)}
            />
          </div>
          <div className="dropdown-group">
            <label className="dropdown-label" htmlFor="date-to">
              Date To
            </label>
            <input
              type="date"
              className="filter-dropdown"
              id="date-to"
              value={filtertoDate}
              onChange={(e) => setFiltertoDate(e.target.value)}
            />
          </div>
          <div className="button-group-end">
            <button className="submit-button" onClick={handleApplyFilter}>
              Apply Filter
            </button>
          </div>
        </div>
      </Modal>
      <ToastContainer />

      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Delete Appointment"
        className="delete-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="delete-modal-content">
          <p className="delete-modal-text">Are you sure you want to delete this appointment?</p>
          <p className="delete-modal-subtext">This action cannot be undone.</p>
          <div className="delete-modal-buttons">
            <button className="delete-button" onClick={handleDeleteAppointment}>
              <TrashIcon className="trash-icon" />
              Delete
            </button>
            <button className="cancel-button" onClick={() => setIsDeleteModalOpen(false)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Appointments;
