import React from 'react';
import ReactDOM from 'react-dom/client';
import './sentry.ts';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import Userfront from '@userfront/toolkit';
import Modal from 'react-modal';

// Set the app element for Modal
Modal.setAppElement('#root');

(async function () {
  await Userfront.init(process.env.REACT_APP_USERFRONT_TENANT_ID);
})();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
