import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Navbar.css';
import { LogoutButton } from '@userfront/toolkit';
import {
  OverviewDefault,
  OverviewSelected,
  AppointmentsDefault,
  AppointmentsSelected,
  SettingsDefault,
  SettingsSelected,
  ReactivationSelected,
  ReactivationDefault,
} from '../icons';
import { ParbProduct } from '../network/users.ts';
import { UserContext } from './UserContext.tsx';

const Navbar = () => {
  const [isHidden, setIsHidden] = useState(false);
  const { userAccessibleProducts } = useContext(UserContext);

  return (
    <div className={`navbar-wrapper ${isHidden ? 'hidden' : ''}`}>
      <div className={`navbar-container ${isHidden ? 'hidden' : ''}`}>
        <NavLink to="/home" className={`logo ${isHidden ? 'hidden' : ''}`}>
          Parb
        </NavLink>

        {!isHidden && (
          <>
            <div className={`nav-title ${isHidden ? 'hidden' : ''}`}>Main Menu</div>

            <div className="nav-links">
              <NavLink
                to="/home"
                className={`nav-link ${isHidden ? 'hidden' : ''}`}
                isActive={(match) => match && match.isExact}
              >
                {({ isActive }) => (
                  <span>{isActive ? <OverviewSelected /> : <OverviewDefault />} Overview</span>
                )}
              </NavLink>
              {userAccessibleProducts?.includes(ParbProduct.APPOINTMENTS) && (
                <NavLink to="/appointments" className={`nav-link ${isHidden ? 'hidden' : ''}`}>
                  {({ isActive }) => (
                    <span>
                      {isActive ? <AppointmentsSelected /> : <AppointmentsDefault />} Appointment
                      Confirmation
                    </span>
                  )}
                </NavLink>
              )}
              {userAccessibleProducts?.includes(ParbProduct.REACTIVATIONS) && (
                <NavLink to="/reactivation" className={`nav-link ${isHidden ? 'hidden' : ''}`}>
                  {({ isActive }) => (
                    <span>
                      {isActive ? <ReactivationSelected /> : <ReactivationDefault />} Patient
                      Reactivation
                    </span>
                  )}
                </NavLink>
              )}
              <div className={`nav-title ${isHidden ? 'hidden' : ''}`}>Other Settings</div>
              <NavLink to="/settings" className={`nav-link ${isHidden ? 'hidden' : ''}`}>
                {({ isActive }) => (
                  <span>{isActive ? <SettingsSelected /> : <SettingsDefault />} Settings</span>
                )}
              </NavLink>
              <LogoutButton />
            </div>
          </>
        )}
      </div>

      <button
        className={`toggle-button ${isHidden ? 'hidden' : ''}`}
        onClick={() => setIsHidden(!isHidden)}
      >
        {isHidden ? '>' : '<'}
      </button>
    </div>
  );
};

export default Navbar;
