import { get, getFile, uploadCSV } from './methods.ts';
import { CallOutcome, CallStatus } from './sse.ts';
import { OrderDirection, PaginatedResponse } from './types.ts';

export type GetAppointmentResponse = {
  id: number;
  patientId: number;
  patientName?: string;
  patientPhoneNumber?: string;
  clinicId: number;
  clinicName?: string;
  appointmentDate: string;
  appointmentTime: string;
  outcome: CallOutcome;
  status: CallStatus;
};

export const getAppointments = async (
  clinicIds: string,
  fromDate: string,
  toDate: string,
  orderBy?: ApptOrderBy,
  orderDirection?: OrderDirection,
  limit?: number,
  offset?: number,
) => {
  return await get<PaginatedResponse<GetAppointmentResponse>>(`appointments`, {
    clinicIds,
    fromDate,
    toDate,
    orderBy,
    orderDirection,
    limit,
    offset,
  });
};

export const exportAppointmentsCSV = async (
  clinicIds: string,
  fromDate: string,
  toDate: string,
) => {
  return await getFile(`clinic-management/appointments/export`, { clinicIds, fromDate, toDate });
};

export const uploadAppointmentsCSV = async (formData: FormData) => {
  return await uploadCSV('clinic-management/upload-csv', formData);
};

export enum ApptOrderBy {
  CREATED_AT = 'createdAt',
  APPOINTMENT_DATE = 'appointmentDate',
  APPOINTMENT_TIME = 'appointmentTime',
  PATIENT_NAME = 'patientName',
  PATIENT_PHONE = 'patientPhoneNumber',
  CLINIC_NAME = 'clinicName',
}
