import { get, getFile, uploadCSV } from './methods.ts';
import { CallOutcome, CallStatus } from './sse';
import { OrderDirection, PaginatedResponse } from './types.ts';

export type GetReactivationResponse = {
  id: number;
  patientId: number;
  patientName?: string;
  patientPhoneNumber?: string;
  clinicId: number;
  clinicName?: string;
  lastVisit: string;
  primaryProvider?: string;
  outcome: CallOutcome;
  status: CallStatus;
};

export const getReactivations = async (
  clinicIds: string,
  fromDate: string,
  toDate: string,
  orderBy?: ReactivationOrderBy,
  orderDirection?: OrderDirection,
  limit?: number,
  offset?: number,
) => {
  return await get<PaginatedResponse<GetReactivationResponse>>(`reactivations`, {
    clinicIds,
    fromDate,
    toDate,
    orderBy,
    orderDirection,
    limit,
    offset,
  });
};

export const exportReactivationsCSV = async (
  clinicIds: string,
  fromDate: string,
  toDate: string,
) => {
  return await getFile(`clinic-management/reactivations/export`, { clinicIds, fromDate, toDate });
};

export const uploadReactivationsCSV = async (formData: FormData) => {
  return await uploadCSV(`clinic-management/upload-reactivations-csv`, formData);
};

export enum ReactivationOrderBy {
  CREATED_AT = 'createdAt',
  PATIENT_NAME = 'patientName',
  PATIENT_PHONE = 'patientPhoneNumber',
  LAST_VISIT = 'lastVisit',
  PRIMARY_PROVIDER = 'primaryProvider',
  CLINIC_NAME = 'clinicName',
}
