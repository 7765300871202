import React from 'react';
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from './UserContext.tsx';

export const RequireAuth = ({ children, productName }) => {
  const location = useLocation();
  const { userAccessibleProducts } = useContext(UserContext);

  if (!userAccessibleProducts?.includes(productName)) {
    return <Navigate to="/home" state={{ from: location }} />;
  }

  return children;
};
