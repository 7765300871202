import { get } from './methods.ts';

export type GetClinicResponse = {
  clinicId: number;
  clinicName: string;
};

export const getClinics = async (email: string) => {
  return await get<GetClinicResponse[]>(`clinic-management/get-user-clinics`, { email });
};
