import axios from 'axios';
import UserFront from '@userfront/toolkit/react';
import _ from 'lodash';

export const get = async <T>(endpoint: string, params?: Record<string, any>, useAuth = true) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const headers = {
    Authorization: useAuth ? `Bearer ${UserFront.tokens.accessToken}` : '',
  };

  const searchParams = params
    ? `?${new URLSearchParams(_.omitBy(params, _.isUndefined)).toString()}`
    : '';
  return await axios.get<undefined, { data: T }>(`${baseUrl}/${endpoint}${searchParams}`, {
    headers,
  });
};

export const getFile = async (endpoint: string, params?: Record<string, any>, useAuth = true) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const headers = {
    Accept: '*/*',
    Authorization: useAuth ? `Bearer ${UserFront.tokens.accessToken}` : '',
  };
  const searchParams = params ? `?${new URLSearchParams(params).toString()}` : '';
  return await axios.get(`${baseUrl}/${endpoint}${searchParams}`, {
    headers,
    responseType: 'arraybuffer',
  });
};

export const post = async (
  endpoint: string,
  body: any,
  headers?: Record<string, any>,
  useAuth = true,
) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  headers = {
    'Content-Type': 'application/json',
    Authorization: useAuth ? `Bearer ${UserFront.tokens.accessToken}` : '',
    ...headers,
  };
  return await axios.post(`${baseUrl}/${endpoint}`, body, { headers });
};

export const del = async (endpoint: string, useAuth = true) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const headers = {
    Authorization: useAuth ? `Bearer ${UserFront.tokens.accessToken}` : '',
  };
  return await axios.delete(`${baseUrl}/${endpoint}`, { headers });
};

export const uploadCSV = async (endpoint: string, formData: FormData, useAuth = true) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const headers = {
    Authorization: useAuth ? `Bearer ${UserFront.tokens.accessToken}` : '',
  };
  return await axios.post(`${baseUrl}/${endpoint}`, formData, { headers });
};
