import polyfilledEventSource from '@sanity/eventsource/browser';
import Userfront from '@userfront/toolkit';

export enum SseTopic {
  CALL_STATUS = 'call.status',
  CALL_OUTCOME = 'call.outcome',
}

export enum CallOutcome {
  CONFIRMED = 'CONFIRMED', // For appointments only
  NOT_INTERESTED = 'NOT_INTERESTED', // For reactivations only
  TRANSFERRED = 'TRANSFERRED',
  VM_SUCCESSFUL = 'VM_SUCCESSFUL',
  VM_FAILED = 'VM_FAILED',
  FAILED = 'FAILED',
}

export enum CallStatus {
  PENDING = 'PENDING',
  INITIATED = 'INITIATED',
  RINGING = 'RINGING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export interface SseMessage<T> {
  data: T;
}

export type SseCallOutcomeChangedMessage = SseMessage<{
  callId: number;
  callOutcome: CallOutcome;
}>;

export const subscribeToSse = () => {
  const eventSource = new polyfilledEventSource(`${process.env.REACT_APP_API_BASE_URL}/sse`, {
    headers: { Authorization: `Bearer ${Userfront.tokens.accessToken}` },
  });

  eventSource.onerror = (event) => {
    console.error(event);
  };

  return eventSource;
};
