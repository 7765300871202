import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home';
import Appointments from './Appointments';
import Navbar from './Navbar';
import Login from './Login';
import Reactivation from './Reactivation';
import Userfront from '@userfront/toolkit';
import styled from 'styled-components';

import { ParbProduct } from '../network/users.ts';
import { RequireAuth } from './RequireAuth.tsx';
import { UserContextProvider } from './UserContext.tsx';
import { ToastContainer } from 'react-toastify';

const AppContainer = styled.div`
  display: flex;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const App = () => {
  const location = useLocation();

  if (!Userfront.tokens.accessToken) {
    return (
      <AppContainer>
        <AppContent>
          <Login />
        </AppContent>
      </AppContainer>
    );
  }

  // Define the paths where you do not want to display the Navbar
  const noNavbarPaths = ['/'];

  return (
    <AppContainer>
      {!noNavbarPaths.includes(location.pathname) && <Navbar />}
      <AppContent>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route
            path="/appointments"
            element={
              <RequireAuth productName={ParbProduct.APPOINTMENTS}>
                <Appointments />
              </RequireAuth>
            }
          />
          <Route
            path="/reactivation"
            element={
              <RequireAuth productName={ParbProduct.REACTIVATIONS}>
                <Reactivation />
              </RequireAuth>
            }
          />
          {/* Other routes */}
        </Routes>
      </AppContent>
    </AppContainer>
  );
};

const AppWrapper = () => (
  <Router>
    <UserContextProvider>
      <App />
    </UserContextProvider>
    <ToastContainer />
  </Router>
);

export default AppWrapper;
